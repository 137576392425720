/* src/Swiper.css */

.swiper-container {
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 50px auto;
  display: flex;
  align-items: center;
  overflow: hidden;
  padding-left: 10px;
  padding-right: 10px;
  /* Initialize CSS variable */
  --items-to-show: 4;
}

.swiper-wrapper {
  display: flex;
  transition: transform 0.5s ease-in-out;
  width: 100%;
}

.swiper-slide {
  /* The width is dynamically set via CSS variable */
  flex: 0 0 calc(100% / var(--items-to-show));
  box-sizing: border-box;
  padding: 10px;
}

.slide-content {
  background-color: #f0f0f0;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  border-radius: 8px;
}

/* Arrow Buttons */
.swiper-button {
  background-color: white;
  border: 2px solid #000;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 10; /* Ensure it's above other elements */
  top: 50%;
  transform: translateY(-50%);
}

.swiper-button.prev {
  left: 0px; /* Positioned inside the container */
}

.swiper-button.next {
  right: 0px; /* Positioned inside the container */
}

.swiper-button:hover {
  background-color: #e0e0e0;
}

/* Responsive Adjustments */
@media (max-width: 1200px) and (min-width: 992px) {
  .slide-content {
    height: 140px;
  }
}

@media (max-width: 992px) and (min-width: 768px) {
  .slide-content {
    height: 130px;
  }
}

@media (max-width: 768px) {
  .slide-content {
    height: 120px;
  }

  .swiper-button {
    width: 35px;
    height: 35px;
    font-size: 16px;
  }

  .swiper-button.prev {
    left: 5px;
  }

  .swiper-button.next {
    right: 5px;
  }
}

@media (max-width: 480px) {
  .slide-content {
    height: 100px;
  }

  .swiper-button {
    width: 30px;
    height: 30px;
    font-size: 14px;
  }
}
