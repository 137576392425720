.toolbox {
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 5px 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
}

.toolbox button {
  background: none;
  border: none;
  cursor: pointer;
  margin-right: 10px;
}

.toolbox button:disabled {
  color: #ccc;
  cursor: not-allowed;
}

.toolbox button:hover:not(:disabled) {
  color: #007bff;
}
