/* PreviewCard.css */

.card-container {
  /* Common styles for the card */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-wrapper {
  position: relative;
  border: 0.2rem solid black;
  border-radius: 1rem;
  overflow: hidden;
  transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
}

.content-image {
  width: 100%;
  height: auto;
  object-fit: contain;
  transition: transform 0.3s ease-out;
}

.reflection {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20%; /* Adjust the height of the reflection */
  background: linear-gradient(to top, rgba(0, 0, 0, 0.2), transparent);
  pointer-events: none;
}

/* Styles when showing a single item */
.single-item-container .image-wrapper {
  width: 80%; /* Adjust to diminish the image size */
}

.single-item-container .content-image {
  /* Optionally, apply additional transformations or styles */
  /* For example, reduce opacity or scale */
  transform: scale(0.9);
}

/* Styles when showing multiple items */
.multiple-items-container .image-wrapper {
  width: 100%;
}

.multiple-items-container .content-image {
  transform: scale(1);
}

/* Optional: Hover Effects */
.image-wrapper:hover {
  cursor: pointer;
}
