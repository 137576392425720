.card-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 40px 0;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid #ddd;
  height: auto;
}

.card-wrapper:hover {
  transform: scale(
    1.1
  ); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  -o-transition: opacity 0.2s ease-in-out;
  -ms-transition: opacity 0.2s ease-in-out;
  -moz-transition: opacity 0.2s ease-in-out;
  -webkit-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
  background-color: pink;
  cursor: pointer;
}

.card-wrapper.left .card-content {
  order: 1;
  text-align: left;
}

.card-wrapper.left .card-image {
  order: 2;
  margin-left: 20px;
}

.card-wrapper.right .card-content {
  order: 2;
  text-align: right;
  margin-right: 20px;
}

.card-wrapper.right .card-image {
  order: 1;
}

.card-content {
  flex: 1;
}

.card-image {
  flex: 1;
  max-width: 400px; /* Ensure all images have the same max-width */
  text-align: center;
}

.card-image img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

@media (max-width: 768px) {
  .card-wrapper {
    flex-direction: column;
    text-align: center;
  }

  .card-wrapper.left .card-content,
  .card-wrapper.right .card-content {
    order: 1;
    margin-left: 0;
    margin-right: 0;
  }

  .card-wrapper.left .card-image,
  .card-wrapper.right .card-image {
    order: 2;
    margin: 20px 0 0 0;
  }
}
